import { LocalStorageService } from "@/services/local_storage_service";
import {
  Credentials,
  PasswordChangeCredentials,
  UserAuthResponse,
} from "@/models/auth";
import { AxiosResponse, AxiosInstance } from "axios";
import Api from "./ia_api_service";

export default class AuthService {
  private static api: AxiosInstance = Api.instance();

  static login(
    credentials: Credentials
  ): Promise<AxiosResponse<UserAuthResponse>> {
    return this.api.post("/users/authenticate", credentials);
  }

  static changePassword(
    credentials: PasswordChangeCredentials
  ): Promise<AxiosResponse> {
    return this.api.post("/users/changePassword", credentials);
  }

  static logout(): void {
    LocalStorageService.instance().removeUser();
    LocalStorageService.instance().removeAccessToken();
  }
}
