<template>
  <div class="password-reset">
    <p class="password-reset__label">Passwort vergessen?</p>
    <router-link class="password-reset__link" :to="{ name: 'PasswordReset' }">
      Hier neues Passwort beantragen
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/components/login/login-password-reset";
</style>
