import { UserModel } from "@/models/user";
import { AxiosInstance, AxiosResponse } from "axios";
import Api from "./ia_api_service";

export default class UserService {
  private static api: AxiosInstance = Api.instance();

  static getUserInfoById(userId: number): Promise<AxiosResponse<UserModel>> {
    return this.api.get(`/users/ByUserId/${userId}`);
  }
}
